var site = site || {};

site.changeLocale = function (country, lang) {
    var postfixes = {};
    postfixes[lang] = lang;
    postfixes[country] = country.toLowerCase();

    var locale_cookie = lang + '_' + country.toUpperCase();
    generic.cookie('LOCALE', locale_cookie, {path : '/'});
};

$( document ).ready(function() {
  $('.js-product-sort.mpp__product-sort option').each(function() {
    if ( $(this).val() == '' ) {
      $(this).remove();
    }
  });
  $(".js-product-sort-selectBox-dropdown-menu li").each(function() {
    if(!$(this).text()) {
      $(this).remove();
    };
  });
});
